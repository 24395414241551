/* eslint-disable */

const { globalHistory } = require('@reach/router');

function handleAccessibilityFocus() {
  const elementsWithA11yFocus = [...document.querySelectorAll('[data-a11y]')];

  document.addEventListener('keyup', event => {
    elementsWithA11yFocus.forEach(element => {
      if (element === event.target || element.contains(event.target)) {
        element.setAttribute('data-a11y', 'true');
      } else {
        element.setAttribute('data-a11y', 'false');
      }
    });
  });

  // On mouse click change data-a11y attribute false
  document.addEventListener('mousedown', event => {
    elementsWithA11yFocus.forEach(element => {
      if (element === event.target || element.contains(event.target)) {
        element.setAttribute('data-a11y', 'false');
      }
    });
  });
}

module.exports = () => {
  localStorage.removeItem('previousPath');

  setTimeout(() => {
    handleAccessibilityFocus();
  }, 1000);

  /**
   * This is a workaround for a bug in Gatsby
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();

  if(document !== 'undefined') {
    const head = document.head;
    const script = document.createElement('script');
    script.id = 'cloudflare-web-analytics'
    script.defer = true;
    script.src = 'https://static.cloudflareinsights.com/beacon.min.js';
    script.setAttribute('data-cf-beacon', `{"token": "12b797d365dd415e8a42ebfbbb653cbb"}`);
  
    const existingScript = head.querySelector(`#${script.id}`);
  
    if(existingScript) {
      head.removeChild(existingScript)
    }
  
    head.appendChild(script);
  }
};
