// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article.template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-articles-template-tsx": () => import("./../../../src/templates/articles.template.tsx" /* webpackChunkName: "component---src-templates-articles-template-tsx" */),
  "component---src-templates-author-template-tsx": () => import("./../../../src/templates/author.template.tsx" /* webpackChunkName: "component---src-templates-author-template-tsx" */),
  "component---src-templates-home-template-tsx": () => import("./../../../src/templates/home.template.tsx" /* webpackChunkName: "component---src-templates-home-template-tsx" */),
  "component---src-templates-idea-template-tsx": () => import("./../../../src/templates/idea.template.tsx" /* webpackChunkName: "component---src-templates-idea-template-tsx" */),
  "component---src-templates-ideas-template-tsx": () => import("./../../../src/templates/ideas.template.tsx" /* webpackChunkName: "component---src-templates-ideas-template-tsx" */),
  "component---src-templates-note-template-tsx": () => import("./../../../src/templates/note.template.tsx" /* webpackChunkName: "component---src-templates-note-template-tsx" */),
  "component---src-templates-notes-template-tsx": () => import("./../../../src/templates/notes.template.tsx" /* webpackChunkName: "component---src-templates-notes-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag.template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */),
  "component---src-templates-tools-template-tsx": () => import("./../../../src/templates/tools.template.tsx" /* webpackChunkName: "component---src-templates-tools-template-tsx" */)
}

